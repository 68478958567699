import controller from "../artifacts/contracts/controller/Controller.sol/Controller.json"
import gatedController from "../artifacts/contracts/controller/GatedController.sol/GatedController.json"
import registry from "../artifacts/contracts/registry/CRSRegistry.sol/CRSRegistry.json"
import nft from "../artifacts/contracts/nft/BaseNFT.sol/BaseNFT.json"
import resolver from "../artifacts/contracts/resolver/DefaultResolver.sol/DefaultResolver.json"

export {
  controller,
  gatedController,
  registry,
  nft,
  resolver
}
export default {
  controller,
  gatedController,
  registry,
  nft,
  resolver
}