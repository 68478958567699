import {resolver as resolverConfig} from "./artifacts"
import {initContract} from "./utils"

const getText = (node, key, contract_address) =>
  initContract(contract_address, resolverConfig.abi)
    .then(contract => contract.text(node, key))

const setText = (node, key, value, contract_address) =>
  initContract(contract_address, resolverConfig.abi, false)
    .then(contract => contract.setText(node, key, value))

const getProxyConfig = (node, controller, selector, contract_address) =>
  initContract(contract_address, resolverConfig.abi)
    .then(contract => contract.getProxyConfig(node, controller, selector))

const setProxyConfig = (node, controller, selector, proxy, contract_address) =>
  initContract(contract_address, resolverConfig.abi, false)
    .then(contract => contract.setProxyConfig(node, controller, selector, proxy))

export {
  getText,
  setText,
  getProxyConfig,
  setProxyConfig
}
export default {
  getText,
  setText,
  getProxyConfig,
  setProxyConfig
}