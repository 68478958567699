import {ethers} from "ethers"
import artifacts from './artifacts'
import defaultController from './controller'
import gateController from './gated_controller'
import utils from './utils'
import nft from './nft'
import crs from './crs'
import resolver from './resolver'

const controller = Object.assign(defaultController, gateController)

export {
  ethers,
  artifacts,
  utils,
  controller,
  nft,
  resolver,
  crs
}