import {ethers} from "ethers"
import {controller as controllerConfig} from "./artifacts"
import {gasFee} from "./nft"
import {resolver as crsResolver} from "./crs"
import {initContract} from "./utils"

const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

const _getGasFee = (contract, duration) =>
  contract.base()
    .then((nft_address) => gasFee(duration, nft_address))
    .then(response => response[1])

const available = (name, contract_address) => 
  initContract(contract_address, controllerConfig.abi)
    .then(contract => contract.available(name))

const rentPrice = (name, duration, contract_address) =>
  initContract(contract_address, controllerConfig.abi)
    .then(contract => contract.rentPrice(name, duration))

const getDomainSeparator = (contract_address) =>
  initContract(contract_address, controllerConfig.abi)
    .then(contract => contract.getDomainSeparator())

const makeCommitment = (name, owner, secret, resolver = null, addr = null, contract_address) =>
  initContract(contract_address, controllerConfig.abi)
    .then(contract => {
      if (ethers.utils.isAddress(resolver)) return Promise.resolve([contract, resolver])
      
      return Promise.all([
        contract.crs(),
        contract.baseNode()
      ]).then(([crs_address, baseNode]) => {
        return Promise.all([
          contract,
          crsResolver(baseNode, crs_address)
        ])
      })
    })
    .then(([contract, resolver]) => {
      if (!ethers.utils.isAddress(addr)) addr = owner
      return contract.makeCommitmentWithConfig(name, owner, secret, resolver, addr)
    })

const commit = (commitment, contract_address) =>
  initContract(contract_address, controllerConfig.abi, false)
    .then(contract => contract.commit(commitment))

const whitelistedCommit = (name, commitment, pass, contract_address) =>
  initContract(contract_address, controllerConfig.abi, false)
    .then(contract => contract.commit(name, commitment, pass))

const register = (name, owner, duration, secret, resolver = null, addr = null, contract_address) =>
  initContract(contract_address, controllerConfig.abi, false)
    .then(contract => {
      if (ethers.utils.isAddress(resolver)) {
        return Promise.all([
          contract,
          resolver,
          _getGasFee(contract, duration)
        ])
      }

      // Set default resolver for the NFT.
      return Promise.all([
        contract.crs(),
        contract.baseNode()
      ]).then(([crs_address, baseNode]) => {
        return Promise.all([
          contract,
          crsResolver(baseNode, crs_address),
          _getGasFee(contract, duration)
        ])
      })
    })
    .then(([contract, resolver, fee]) => {
      return contract.rentPrice(name, duration)
        .then((rent) => {
          if (!ethers.utils.isAddress(addr)) addr = owner
          if (rent[0] === ADDRESS_ZERO) {
            return contract.registerWithConfig(name, owner, duration, secret, resolver, addr, {value: rent[1].add(fee)})
          } else if (!fee.isZero()) {
            return contract.registerWithConfig(name, owner, duration, secret, resolver, addr, {value: fee})
          } else {
            return contract.registerWithConfig(name, owner, duration, secret, resolver, addr)
          }
        })
    })

export {
  available,
  rentPrice,
  getDomainSeparator,
  makeCommitment,
  commit,
  whitelistedCommit,
  register
}
export default {
  available,
  rentPrice,
  getDomainSeparator,
  makeCommitment,
  commit,
  whitelistedCommit,
  register
}