import {gatedController as controllerConfig} from "./artifacts"
import {initContract} from "./utils"

const getDomainSeparator = (contract_address) =>
  initContract(contract_address, controllerConfig.abi)
    .then(contract => contract.getDomainSeparator())

const whitelistedCommit = (name, commitment, pass, contract_address) =>
  initContract(contract_address, controllerConfig.abi, false)
    .then(contract => contract['commit(string,bytes32,bytes)'](name, commitment, pass))

export {
  getDomainSeparator,
  whitelistedCommit
}
export default {
  getDomainSeparator,
  whitelistedCommit
}